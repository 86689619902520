<template>
  <div class="accent">
    <v-container fluid>
      <v-row class="mx-auto py-4">
        <v-col cols="12">
          <h1>
            <router-link :to="{ name: 'Management' }">Management</router-link>
            | <span class="title"> Access Panel</span>
          </h1>
        </v-col>
      </v-row>
      <v-container>
        <v-row class="mt-10">
          <v-col class="mx-auto" cols="12" md="12">
            <team-menu />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {
    teamMenu: () => import('@/components/team-managment/menu'),
  },
  props: {
    source: String,
  },
  data: () => ({
    dialog: false,
    drawer: null,
    snackback: false,
  }),
}
</script>
